<template>
  <div class="admin-user-edit">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Admin User Edit</h2></b-card-header>
        <b-card-body>
          <admin-edit-user :userId="userId"></admin-edit-user>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import AdminEditUser from '../../components/admin/AdminEditUser'
export default {
  name: 'admin-user-edit-view',
  components: {
    AdminEditUser
  },
  data: function () {
    return {
      userId: null
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('user_id')) {
      console.log('Viuew ROute: ', this.$route.params.user_id)
      this.userId = this.$route.params.user_id
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.admin-user-edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}

.list-containter {
  background: $theme-color-background-4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}

</style>
